import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImage from '../assets/images/login.png'; // Ensure the path is correct
import './Login.css';
import axios from 'axios';
import { API_URL } from '../utils/config';
import { handleNon200Response } from '../utils/handleNon200Response';

function Login() {


    const [autoNumber, setAutoNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        if (!autoNumber || !phoneNumber) {
            alert('Please enter both Auto Number and Phone Number.');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/api/login`, {
                autoNumber,
                phoneNumber
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                navigate(`/profile/${response.data.token}`);
            } else {
                handleNon200Response(response);
            }
        } catch (error) {
            if (error.response) {
                handleNon200Response(error.response);
            } else {
                alert('Unexpected Error!');
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="login-container">
            <div className="login-image">
                <img src={loginImage} alt="Login" />
            </div>
            <div className="login-form">
                <h1>Login</h1>
                <input type="text" placeholder="Auto Number (KAXXXXXXXX)" value={autoNumber} onChange={(e) => setAutoNumber(e.target.value)} />
                <input type="password" placeholder="Mobile Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                <button type="submit" onClick={handleSubmit}>Login</button>
            </div>
        </div>
    );
}

export default Login;

