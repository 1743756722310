import './auto-list.css'
import driver from '../assets/images/Driver.jpg'
import callIcon from '../assets/images/call-now.png'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/config';
import { handleNon200Response } from '../utils/handleNon200Response';

function AutoList() {
    const [autoList, setAutoList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAutoList = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${API_URL}/api/register/list`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setAutoList(response.data.items);
            } else {
                handleNon200Response(response);
            }
        } catch (error) {
            if (error.response) {
                handleNon200Response(error.response);
            } else {
                alert('Unexpected Error!');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCallClick = async (phoneNumber) => {
        try {
            // API call to increment the call counter
            await axios.post(`${API_URL}/api/increment-call-counter`, { phoneNumber });
            // Proceed to initiate the call
            window.location.href = `tel:+91${phoneNumber}`;
        } catch (error) {
            console.error('Failed to increment call counter:', error);
        }
    };

    useEffect(() => {
        fetchAutoList();
    }, []);

    return (
        <div className='auto-list-container'>
            <div className='title'>
                <p>Online Auto Drivers ({autoList.length})</p>
            </div>
            {autoList.map((item) => (
                <div className="driver-card" key={item._id}>
                    <img src={driver} alt="Driver" className="driver-icon" />
                    <div className="driver-info">
                        <h3>{item.firstName ? item.firstName.replace(/</g, "&lt;").replace(/>/g, "&gt;") : ''} {item.lastName ? item.lastName.replace(/</g, "&lt;").replace(/>/g, "&gt;") : ''}</h3>
                        <p>Blood Group: {item.bloodGroup ? item.bloodGroup.replace(/</g, "&lt;").replace(/>/g, "&gt;") : 'N/A'}</p>
                        <p>Auto Number: {item.autoNumber ? item.autoNumber.replace(/</g, "&lt;").replace(/>/g, "&gt;") : 'N/A'}</p>
                    </div>
                    <a href={`tel:+91${item.phoneNumber}`} className="call-button" onClick={() => handleCallClick(item.phoneNumber)}>
                        <img src={callIcon} alt="Call Now" />
                        Call now
                    </a>
                </div>
            ))}
        </div>
    )
}

export default AutoList