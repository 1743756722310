import React from 'react';
import './Footer.css'; 
import logo from '../triyatri-logo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <div className="footer">
            <div className='footer-logo-container'>
                <img src={logo} alt="Footer Logo" className="footer-logo" />
            </div>
            <div className='footer-text-container'>
                <div className='footer-elements'>
                    <div className='footer-text'>
                        <p>About Us</p>
                        <p><Link to="/contactus">Contact Us</Link></p>
                    </div>
                    <div className='footer-social-media'>
                        <p className="footer-text">Follow us on: &nbsp;</p>
                        <a href="https://www.instagram.com/triyatri.app/" target="_blank" rel="noopener noreferrer" className='footer-social-media-icon'><InstagramIcon /></a>
                    </div>
                </div>
                <div className='footer-watermark'>Powered by&nbsp;<a href="https://kishannayak.site" target="_blank" rel="noopener noreferrer">Phoenix Studios.</a>&nbsp;All rights reserved by Triyatri.</div>
            </div>
        </div>
    );
}

export default Footer;
