import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Body from '../components/Body'
import AutoList from '../components/auto-list'
import RegistrationPage from './Registration'
import LoginPage from './Login'
import ProfilePage from './Profile'
import RegSuccessful from '../components/regsuccessful'
import ContactUs from './ContactUs'




function Homepage() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Body />} />
                <Route path="/auto-list" element={<AutoList />} />
                <Route path="/register" element={<RegistrationPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/profile/:autoNumber" element={<ProfilePage />} />
                <Route path="/success" element={<RegSuccessful />} />
                <Route path="/contactus" element={<ContactUs />} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default Homepage