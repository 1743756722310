import React, { useState, useEffect } from 'react'
import './Profile.css'
import profileImage from '../assets/images/Driver.jpg'
import axios from 'axios';
import { API_URL } from '../utils/config';
import { handleNon200Response } from '../utils/handleNon200Response';
import { useParams } from 'react-router-dom';

function Profile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { autoNumber } = useParams();
    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/api/profile/${autoNumber}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setProfile(response.data.profile);
            } else {
                handleNon200Response(response);
            }
        } catch (error) {
            if (error.response) {
                handleNon200Response(error.response);
            } else {
                alert('Unexpected Error!');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleToggle = async () => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`${API_URL}/api/status/${autoNumber}`, {
                online: !profile.online
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                fetchProfile();
            } else {
                handleNon200Response(response);
            }
        } catch (error) {
            if (error.response) {
                handleNon200Response(error.response);
            } else {
                alert('Unexpected Error!');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-card">
            <div className="profile-image">
                <img src={profileImage} alt="Profile" />
            </div>
            {profile && (
                <>
                    <div className="profile-info">
                        <h1>{profile.firstName} {profile.lastName}</h1>
                        <p>DOB: {profile.dob}</p>
                        <p>+91 {profile.phoneNumber}</p>
                        <p>Blood Group: {profile.bloodGroup}</p>
                        <p>Auto Number: {profile.autoNumber}</p>
                    </div>
                    <div className="profile-status">
                        <button onClick={handleToggle} className="toggle-button" style={{ backgroundColor: profile.online ? '#28a745' : '#dc3545' }}>
                            {profile.online ? 'Online' : 'Offline'}
                        </button>
                        <span className="status-dot" style={{ backgroundColor: profile.online ? '#00ff00' : '#ff0000' }}></span>
                    </div>
                </>
            )}
        </div>
    )
}

export default Profile