import React from 'react';
import './regsuccessful.css';
import successImage from '../assets/images/success.png';

function regsuccessful() {
  return (
    <div className='reg-body'>
    <div className="reg-success-container">
      <img src={successImage} alt="Success" className='success-image'/>
      <div className="reg-success-message">Registration Successful, Click here for profile →</div>
        <button className="reg-success-button">PROFILE</button>
      </div>
    </div>
  );
}

export default regsuccessful;