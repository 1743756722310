import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../triyatri-logo.png';

function Header() {
    return (
        <div className="header">
            <Link to="/">
                <img src={logo} alt="Logo" className="logo" />
            </Link>
            {localStorage.getItem('token') ? (
                <div className='login-link-container'>
                    <Link to="/profile" className="register-link">Profile</Link>
                    <button onClick={() => {
                        if (window.confirm('Are you sure you want to logout?')) {
                            localStorage.removeItem('token');
                            window.location.href = '/';
                        }
                    }} className="register-link">Logout</button>
                </div>

            ) : (
                <div className='login-link-container'>
                    <Link to="/register" className="register-link">Register</Link>
                    {/* <Link to="/login" className="register-link">Login</Link> */}
                </div>
            )}
        </div>
    );
}

export default Header;