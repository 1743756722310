import React, { useState } from 'react';
import './Form.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../utils/config';
import { handleNon200Response } from '../utils/handleNon200Response';
import './regsuccessful.css';
import successImage from '../assets/images/success.png';

function formIsValid(formData) {
    // Example validation: Ensure all fields are filled
    return Object.values(formData).every(value => value.trim() !== '');
}

function Form() {
    const [auto, setAuto] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        autoNumber: '',
        phoneNumber: '',
        bloodGroup: '',
        dob: '',
        places: [],

    });

    const [showDialog, setShowDialog] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(`Field: ${name}, Value: ${value}`);

        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
                    ? [...prevState[name], value]
                    : prevState[name].filter(item => item !== value)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: name === 'autoNumber' ? value.toUpperCase() : value
            }));
        }
    };

    const handleProfileClick = () => {
        navigate(`/profile/${auto}`);
    };
    console.log(auto);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${API_URL}/api/register`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 201) {

                //console.log(response);
                setAuto(response.data.autoNumber);
                setShowDialog(true);
            } else {
                handleNon200Response(response);
            }
        } catch (error) {
            if (error.response) {
                handleNon200Response(error.response);
            } else {
                alert('Unexpected Error!');
            }
        } finally {
            setLoading(false);
        }
    };

    const Modal = () => (
        <div className="modal">
            <div className="modal-content">
                <div className='reg-body'>
                    <div className="reg-success-container">
                        <img src={successImage} alt="Success" className='success-image' />
                        <div className="reg-success-message">Registration Successful, Click here for profile →</div>
                        <button onClick={handleProfileClick} className="reg-success-button">PROFILE</button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="registration-form">
                <h2>Registration Form</h2>
                <div className="name-row">
                    <input className='input-field' type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                    <input className='input-field' type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
                    <input className='input-field' type="number" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
                    <input className='input-field' type="date" name="dob" placeholder="Date of Birth" value={formData.dob} onChange={handleChange} required />
                    <select className='input-field' name="bloodGroup" value={formData.bloodGroup} onChange={handleChange} required>
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                    </select>
                    <input className='input-field' type="text" name="autoNumber" placeholder="Auto Number - KA XX XX XXXX" value={formData.autoNumber} onChange={handleChange} required />


                    <div className="input-field">
                        <label>Places you serve:</label>
                        <div>
                            <input
                                type="checkbox"
                                name="places"
                                value="Ujire"
                                onChange={handleChange}
                            /> Ujire
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="places"
                                value="Dharmastala"
                                onChange={handleChange}
                            /> Dharmastala
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="places"
                                value="Belthangady"
                                onChange={handleChange}
                            /> Belthangady
                        </div>
                    </div>
                    <div className='register-button-container'>
                        <button
                            className='register-button'
                            type="submit"
                        >
                            Register
                        </button>
                    </div>
                </div>

                {showDialog && <Modal />}
            </form>
        </div>
    );
}

export default Form;