export const handleNon200Response = (response) => {
    let errorMessage;
    if (response.data && response.data.error) {
        errorMessage = response.data.error;
    } else {
        errorMessage = "An unexpected error occurred";
    }

    switch (response.status) {
        case 401:
            alert('Please login.');
            return false;
        case 500:
            alert(`${errorMessage}`);
            return false;
        case 400:
            alert(`${errorMessage}`);
            return false;
        default:
            alert(`Error: ${errorMessage}`);
            return false;
    }
};