import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Body.css'
import poster from '../assets/images/Auto.png'
import location from '../assets/images/when-where.png'
import free from '../assets/images/freeofcost.png'
import payment from '../assets/images/payment-image.png'


function Body() {
  const navigate = useNavigate();

  const navigateToAutoList = () => {
    navigate('/auto-list');
  }

  return (
    <div className='body'> 
        <section className='poster-container'>
            <div className='poster-image'>
                <img src={poster} alt="Poster" />
            </div>
            <div className='poster-content'>
                <h1>Ujire's 1st Auto Booking Platform</h1>
                <p>Try Triyatri, Contact your Autowala with just a click</p>
                <button className='find-now-button' onClick={navigateToAutoList}>Book an Auto →</button>
            </div>
        </section>

        <div className="section-container">

            <div className='illustration-content'>
              <h2>Whenever, Wherever</h2>
              <p>Book your Auto whenever and wherever with the latest available autos</p>
              <p>Find your latest available auto now</p>
              <button className='find-now-button' onClick={navigateToAutoList}>Book an Auto →</button>
            </div>

            <div className='illustration-image'>
              <img src={location} alt="Illustration" style={{width: '50%', height: 'auto'}}/>
            </div>

        </div>

        <div className="alt-section-container">
            <div className='alt-illustration-image'>
              <img src={payment} alt="Illustration" style={{width: '50%', height: 'auto'}}/>
            </div>

            <div className='alt-illustration-content'>
              <h2>Hassle-free Payment</h2>
              <p>We dont interfere with your payment process, <br/> use app for completely free of cost</p>
              <button className='find-now-button' onClick={navigateToAutoList}>Book an Auto →</button>
            </div>
        </div>

        <div className="last-section-container">

            <div className='last-illustration-content'>
              <h2>FREE FREE FREE!</h2>
              <p>No hidden charges, no extra charges, no extra hassles. <br/> Triyatri platform is completely free of cost for both Auto Drivers and Users</p>
              <button className='find-now-button' onClick={navigateToAutoList}>Book an Auto →</button>
            </div>

            <div className='last-illustration-image'>
              <img src={free} alt="Illustration" style={{width: '50%', height: 'auto'}}/>
            </div>

        </div>
        
    </div>
  )
}

export default Body