import React from 'react'
import Form from '../components/Form'

function Registration() {
  return (
    <div>
        <Form />
    </div>
  )
}

export default Registration