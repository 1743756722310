import React from 'react'
import './ContactUs.css'
import contactus from '../assets/images/contact-us.png'

function ContactUs() {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <img src={contactus} alt="contact-us" />
      <h3>Need a hand with Triyatri? Contact Us and we can point you in the right direction</h3>
      <p>For support questions, please contact us via Email at <a href="mailto:team.triyatri@gmail.com">team.triyatri@gmail.com</a> or through <a href="https://www.instagram.com/triyatri.app/">Instagram</a></p>
    </div>
  )
}

export default ContactUs